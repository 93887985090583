/**
 * Invoicingrequests scene sagas
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 *
 */
import { takeLatest, putResolve, call, select } from 'redux-saga/effects'
import API from 'api'
import { actions, RootState } from 'store/rootSlices'
import { toast } from 'react-toastify'
import i18n from 'i18next';
import { AnyAction } from 'redux';
import { Interval } from 'luxon';
import { InvoiceType, SyncStatus, InvoicesList } from 'types/invoicing/invoices';

function* onMountSaga({ payload }: AnyAction) {
  yield getURLParamsSaga(payload);
  yield putResolve(actions.InvoicingRequests.setLoading(false));
}

function* getInvoicesListSaga() {
  yield getInvoicesSaga()
}

function* getInvoicesSaga() {
  try {
    const {
      page,
      filter,
      searchTerm,
      sort,
      startDate,
      endDate,
      syncFilter,
      bookingFilter
    } = yield select((state: RootState) => state.InvoicingRequests);
    
    const invoices: InvoicesList = yield call(
      API.Invoicing.GetInvoices,
      page,
      filter,
      searchTerm,
      sort,
      startDate,
      endDate,
      syncFilter,
      bookingFilter
    );

    if (invoices) {
      yield putResolve(actions.InvoicingRequests.setInvoices(invoices.results))
      yield putResolve(actions.InvoicingRequests.setInvoicesTotal(invoices.count))

    }
  } catch (error) {
    toast.error(i18n.t("toasts.invoicesGetError"))
  }
}

function* getURLParamsSaga(payload: AnyAction) {

  let params = new URLSearchParams(payload.location.search)

  const {
    hasDateParams,
    searchTerm,
    filter,
    startDate,
    endDate,
    syncFilter,
    bookingFilter
  } = yield select((state: RootState) => state.InvoicingRequests);

  if (params.toString() !== "") {
    yield putResolve(actions.InvoicingRequests.populateFiltersOnMount({
      searchTerm: params.get('search') ?? "",
      filter: params.get('type') ?? InvoiceType.NONE,
      syncFilter: params.get('sync') ?? SyncStatus.NONE,
      bookingFilter: params.get('booking') ?? "",
      startDate: params.get('booking') ? "" : params.get('startDate') ?? new Date().toISOString().slice(0, 10),
      endDate: params.get('booking') ? "" : params.get('endDate') ?? new Date().toISOString().slice(0, 10),
    }))
    if (!params.get('booking')) {
      yield putResolve(actions.InvoicingRequests.setHasDateParams(!hasDateParams))
    } else {
      yield getInvoicesSaga()
    }
  }
  else {
    //state.hasDateParams has to be different from the last known state.hasDateParams to force a reRender on datepicker
    //this reRender must be done so the datepicker changes its label
    yield putResolve(actions.InvoicingRequests.setHasDateParams(!hasDateParams));
    params.set('type', filter)
    params.set('sync', syncFilter)
    params.set('search', searchTerm)
    params.set('startDate', startDate)
    params.set('endDate', endDate)
    params.set('booking', bookingFilter)
    payload.replace({ search: (params).toString() })
  }
}

function* setDatesIntervalSaga({ payload }: AnyAction) {

  if (payload.e.startDate && payload.e.endDate) {
    let interval = Interval.fromDateTimes(payload.e.startDate, payload.e.endDate);
    const arr = Array.from(days(interval))

    let params = new URLSearchParams(payload.history.location.search);
    payload.e.startDate !== "" ? params.set('startDate', arr[0]?.toISO().slice(0, 10)) : params.delete('startDate')
    payload.e.endDate !== "" ? params.set('endDate', arr[arr.length - 1]?.toISO().slice(0, 10)) : params.delete('endDate')
    payload.history.replace({ search: (params).toString() })

    yield putResolve(actions.InvoicingRequests.setStartDate(arr[0]?.toISO().slice(0, 10)))
    yield putResolve(actions.InvoicingRequests.setEndDate(arr[arr.length - 1]?.toISO().slice(0, 10)))
  } else {
    let params = new URLSearchParams(payload.history.location.search);
    params.delete('startDate')
    params.delete('endDate')
    payload.history.replace({ search: (params).toString() })

    yield putResolve(actions.InvoicingRequests.setStartDate(""))
    yield putResolve(actions.InvoicingRequests.setEndDate(""))
  }
}

function* onUnmountSaga() {
  yield putResolve(actions.InvoicingRequests.setInvoices([]))
  yield putResolve(actions.InvoicingRequests.setLoading(true));
}

function* days(interval) {
  let cursor = interval.start.startOf("day");

  while (cursor <= interval.end) {
    yield cursor;
    cursor = cursor.plus({ days: 1 });
  }
}

function* exportInvoicesSaga() {
  yield putResolve(actions.InvoicingRequests.setExportLoading(true))

  const { startDate } = yield select((state: RootState) => state.InvoicingRequests);

  try {
    const file = yield call(API.Invoicing.ExportInvoices, startDate)
    if (file) {
      yield putResolve(actions.InvoicingRequests.downloadInvoices(file))
      toast.success(i18n.t("toasts.downloadInvoicingListSuccess"))
    }

  } catch (error) {
    toast.error(i18n.t("toasts.downloadInvoicingListError"))
  } finally {
    yield putResolve(actions.InvoicingRequests.setExportLoading(false))
  }
}

function* syncInvoiceSaga({ payload }: AnyAction) {
  yield putResolve(actions.InvoicingRequests.setSyncLoading({isLoading: true, id: payload.id}))
  const { syncFilter } = yield select((state: RootState) => state.InvoicingRequests);

  try {
    const invoice = yield call(API.Invoicing.SyncInvoice, payload.id)

    if(invoice) {

      if(syncFilter === 'failed') {
        yield putResolve(actions.InvoicingRequests.removeInvoice(invoice.id))
      } else {
        yield putResolve(actions.InvoicingRequests.updateSynchedInvoice(invoice))
      }

      yield putResolve(actions.InvoicingRequests.setLoading(false));
      toast.success(i18n.t("toasts.syncRequestSuccess"))
    }
  } catch (error) {
    toast.error(i18n.t("toasts.syncRequestError"));
  } finally {
    yield putResolve(actions.InvoicingRequests.setSyncLoading({isLoading: false, id: null}))
  }

}

export default function* watcherSignin() {
  yield takeLatest('InvoicingRequests/exportInvoices', exportInvoicesSaga)
  yield takeLatest('InvoicingRequests/onMount', onMountSaga)
  yield takeLatest('InvoicingRequests/onUnmount', onUnmountSaga)
  yield takeLatest('InvoicingRequests/getURLParams', getURLParamsSaga);
  yield takeLatest('InvoicingRequests/setFilter', getInvoicesListSaga)
  yield takeLatest('InvoicingRequests/setSyncFilter', getInvoicesListSaga)
  yield takeLatest('InvoicingRequests/setSearchTerm', getInvoicesListSaga)
  yield takeLatest('InvoicingRequests/setSort', getInvoicesListSaga)
  yield takeLatest('InvoicingRequests/setPage', getInvoicesListSaga)
  yield takeLatest('InvoicingRequests/getInvoices', getInvoicesListSaga)
  yield takeLatest('InvoicingRequests/setDatesInterval', setDatesIntervalSaga);
  yield takeLatest('InvoicingRequests/syncInvoice', syncInvoiceSaga)
}


