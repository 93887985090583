import axios from 'axios'
import endpoints from 'api/endpoints'
import { generateSortString, generateUrl } from 'utils'
import { decamelizeKeys } from 'humps'
import { SortType } from 'types/misc'


const SyncInvoice = (id: number) => {

    const endpoint = generateUrl(endpoints.INVOICING.SYNC_INVOICE, { id })

    return axios.post(endpoint, id)
        .then(res => res.data)
        .catch(err => { throw err })

}


const ExportInvoices = (date: string) => {

    const endpoint = `${endpoints.INVOICING.EXPORT_INVOICES}?${date}`;
    const token = window.localStorage.getItem("access_token")

    return fetch(endpoint, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        }
    })
        .then(res => res.blob())
        .catch(err => { throw err })
}


const GetInvoices = (
    page: number,
    filter?: string,
    searchTerm?: string,
    ordering?: SortType,
    createdDateAfter?: string,
    createdDateBefore?: string,
    syncFilter?: string,
    bookingFilter?: string,
) => {

    const filterQS = `${filter ? `&type=${filter}` : ''}`;
    const syncFilterQS = `${syncFilter ? `&sync_status=${syncFilter}` : ''}`;
    const searchTermQS = `${searchTerm ? `&search=${searchTerm}` : ''}`;
    const createdDateQS = `${createdDateAfter && createdDateBefore ? `&date_after=${createdDateAfter}&date_before=${createdDateBefore}` : ''}`;
    const orderingQS = ordering ? generateSortString(ordering) : ''
    const bookingFilterQS = `${bookingFilter ? `&booking=${bookingFilter}` : ''}`;

    const endpoint = `
        ${endpoints.INVOICING.GET_INVOICES}?page=${page}${filterQS}${searchTermQS}${orderingQS}${createdDateQS}${syncFilterQS}${bookingFilterQS}
    `;

    return axios.get(endpoint)
        .then(res => res.data)
        .catch(err => { throw err })

}

const GetInvoice = (id: number) => {

    const endpoint = generateUrl(endpoints.INVOICING.GET_INVOICE, { id })

    return axios.get(endpoint)
        .then(res => res.data)
        .catch(err => { throw err })

}

const CreateInvoice = (params) => {

    const decMLZParams = decamelizeKeys(params)

    return axios.post(endpoints.INVOICING.CREATE_INVOICE, decMLZParams)
        .then(res => res.data)
        .catch(err => { throw err })

}

const EditInvoice = (id: number, params) => {

    const formData = new FormData();
    formData.append('file', params)

    const endpoint = generateUrl(endpoints.INVOICING.EDIT_INVOICE, { id })

    return axios.patch(endpoint, formData)
        .then(res => res.data)
        .catch(err => { throw err })
}

const EditInvoiceDetails = (id: number, params) => {
    const decMLZParams = decamelizeKeys(params)
    const endpoint = generateUrl(endpoints.INVOICING.EDIT_INVOICE, { id })

    return axios.patch(endpoint, decMLZParams)
        .then(res => res.data)
        .catch(err => { throw err })
}


const GetEntries = (
    page: number,
    /* pageSize: number */
    processedBy?: string,
    ordering?: SortType,
    searchTerm?: string,
    dateAfter?: string,
    dateBefore?: string,
    paymentType?: string,
    movementType?: string
) => {

    const processedByQS = `${processedBy ? `&processed_by=${processedBy}` : ''}`;
    const paymentTypeQS = `${paymentType ? `&payment_type=${paymentType}` : ''}`;
    const movementTypeQS = `${movementType ? `&balance_entry_type=${movementType}` : ''}`;
    const searchTermQS = `${searchTerm ? `&search=${searchTerm}` : ''}`;
    const orderingQS = ordering ? generateSortString(ordering) : ''
    const tripDateQS = `${dateAfter && dateBefore ? `&date_after=${dateAfter}&date_before=${dateBefore}` : ''}`;

    const endpoint = `
        ${endpoints.INVOICING.GET_ENTRIES}?page=${page}${processedByQS}${searchTermQS}${orderingQS}${tripDateQS}${paymentTypeQS}${movementTypeQS}
    `;

    return axios.get(endpoint)
        .then(res => res.data)
        .catch(err => {
            throw err;
        })
};

const GetEntry = (id: number) => {
    const endpoint = generateUrl(endpoints.INVOICING.GET_ENTRY, { id })

    return axios.get(endpoint)
        .then(res => res.data)
        .catch(err => {
            throw err;
        })
}

const EditEntry = (id: number, params) => {
    const endpoint = generateUrl(endpoints.INVOICING.EDIT_ENTRY, { id })

    return axios.patch(endpoint, params)
        .then(res => res.data)
        .catch(err => {
            throw err;
        })
}

const CreateEntry = (params) => {

    const decMLZParams = decamelizeKeys(params)

    return axios.post(endpoints.INVOICING.CREATE_ENTRY, decMLZParams)
        .then(res => res.data)
        .catch(err => {
            throw err;
        })
}

const ListEntryPhotos = (entry: number) => {
    const entryQS = `balance_entry=${entry}`
    const endpoint = `${endpoints.INVOICING.LIST_ENTRY_PHOTOS}?${entryQS}`;

    return axios.get(endpoint)
        .then(res => res.data)
        .catch(err => {
            throw err;
        })
}

const GetEntryPhoto = (id: number) => {
    const endpoint = generateUrl(endpoints.INVOICING.GET_ENTRY_PHOTOS, { id })

    return axios.get(endpoint)
        .then(res => res.data)
        .catch(err => {
            throw err;
        })
}

const AddEntryPhoto = (params) => {

    const decMLZParams = decamelizeKeys(params)
    const entryPhotoFormData = new FormData();

    Object.keys(decMLZParams).forEach(valKey => {
        if (valKey === "file") {
            entryPhotoFormData.append(valKey, params[valKey])
        }
        else {
            entryPhotoFormData.append(valKey, decMLZParams[valKey])
        }
    })

    return axios.post(endpoints.INVOICING.CREATE_ENTRY_PHOTO, entryPhotoFormData)
        .then(res => res.data)
        .catch(err => {
            throw err
        })
}

const EditEntryPhoto = (id: number, params) => {
    const endpoint = generateUrl(endpoints.INVOICING.EDIT_ENTRY_PHOTO, { id })

    const entryPhotoFormData = new FormData();
    Object.keys(params).forEach(valKey => {
        entryPhotoFormData.append(valKey, params[valKey])
    })

    return axios.patch(endpoint, entryPhotoFormData)
        .then(res => res.data)
        .catch(err => {
            throw err
        })
}

const DeleteEntryPhoto = (id: number) => {
    const endpoint = generateUrl(endpoints.INVOICING.DELETE_ENTRY_PHOTO, { id })

    return axios.delete(endpoint)
        .then(res => res)
        .catch(err => {
            throw err
        })
}

const PrintTicket = (id: number) => {
    const endpoint = generateUrl(endpoints.INVOICING.PRINT_TICKET, { id })

    return axios.get(endpoint)
        .then(res => res.data)
        .catch(err => { throw err })
}

const ObtainTicket = (id: number) => {
    const endpoint = generateUrl(endpoints.INVOICING.OBTAIN_TICKET, { id })

    return axios.get(endpoint)
        .then(res => res.data)
        .catch(err => {
            throw err;
        })
}

const GetStatements = (page: number, searchTerm?: string, groupFilter?: string, dateAfter?: string, dateBefore?: string, uuid?: string) => {
    const searchTermQS = `${searchTerm ? `&search=${searchTerm}` : ''}`;
    const groupFilterQS = `${groupFilter ? `&group=${groupFilter}` : ''}`;
    const dateAfterQS = `${dateAfter ? `&date_after=${dateAfter}` : ''}`;
    const dateBeforeQS = `${dateBefore ? `&date_before=${dateBefore}` : ''}`;
    const teamMemberQS = `${uuid ? `&team_member=${uuid}` : ''}`;


    const endpoint = `${endpoints.INVOICING.CASH_MANAGEMENT.LIST_STATEMENTS}?page=${page}${searchTermQS}${dateAfterQS}${dateBeforeQS}${teamMemberQS}${groupFilterQS}`;

    return axios.get(endpoint)
        .then(res => res.data)
        .catch(err => {
            throw err;
        })
}

const GetStatment = (id: number) => {
    const endpoint = generateUrl(endpoints.INVOICING.CASH_MANAGEMENT.VIEW_STATEMENT, { id })

    return axios.get(endpoint)
        .then(res => res.data)
        .catch(err => {
            throw err;
        })
}

const OpenStatment = (id: number) => {
    const endpoint = generateUrl(endpoints.INVOICING.CASH_MANAGEMENT.OPEN_STATEMENT, { id })

    return axios.post(endpoint)
        .then(res => res.data)
        .catch(err => {
            throw err;
        })
}

const CloseStatement = (id: number, params) => {

    const decMLZParams = decamelizeKeys(params)
    const endpoint = generateUrl(endpoints.INVOICING.CASH_MANAGEMENT.CLOSE_STATEMENT, { id })

    return axios.post(endpoint, decMLZParams)
        .then(res => res.data)
        .catch(err => { throw err })
}

const ApproveStatements = (params) => {
    return axios.post(endpoints.INVOICING.CASH_MANAGEMENT.APPROVE_STATEMENTS, params)
        .then(res => res)
        .catch(err => { throw err })
}

const DisapproveStatements = (params) => {
    return axios.post(endpoints.INVOICING.CASH_MANAGEMENT.DISAPPROVE_STATEMENTS, params)
        .then(res => res)
        .catch(err => { throw err })
}

const GetStatementsTotals = (dateAfter: string, dateBefore: string) => {
    const dateAfterQS = `${dateAfter ? `date_after=${dateAfter}` : ''}`;
    const dateBeforeQS = `${dateBefore ? `&date_before=${dateBefore}` : ''}`;

    const endpoint = `${endpoints.INVOICING.CASH_MANAGEMENT.STATEMENT_TOTALS}?${dateAfterQS}${dateBeforeQS}`;

    return axios.get(endpoint)
        .then(res => res)
        .catch(err => { throw err })
}

const ExportStatements = (dateAfter: string, dateBefore: string, teamMember?: string) => {
    const dateAfterQS = `${dateAfter ? `date_after=${dateAfter}` : ''}`;
    const dateBeforeQS = `${dateBefore ? `&date_before=${dateBefore}` : ''}`;
    const teamMemberQS = `${teamMember ? `&team_member=${teamMember}` : ''}`;

    const endpoint = `${endpoints.INVOICING.CASH_MANAGEMENT.EXPORT_STATEMENTS}?${dateAfterQS}${dateBeforeQS}${teamMemberQS}`;
    const token = window.localStorage.getItem("access_token")

    return fetch(endpoint, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        }
    })
        .then(res => res.blob())
        .catch(err => { throw err })
}

const GetStatementHistory = (id: Number) => {
    const endpoint = generateUrl(endpoints.INVOICING.CASH_MANAGEMENT.STATEMENT_TOTALS, { id })

    return axios.get(endpoint)
        .then(res => res)
        .catch(err => {
            throw err;
        })
}


const ListStatementPhotos = (statement: number) => {
    const statementQS = `statement=${statement}`
    const endpoint = `${endpoints.INVOICING.CASH_MANAGEMENT.LIST_STATEMENT_PHOTOS}?${statementQS}`;

    return axios.get(endpoint)
        .then(res => res.data)
        .catch(err => {
            throw err;
        })
}

const GetStatementPhoto = (id: Number) => {
    const endpoint = generateUrl(endpoints.INVOICING.CASH_MANAGEMENT.GET_STATEMENT_PHOTO, { id })

    return axios.get(endpoint)
        .then(res => res.data)
        .catch(err => {
            throw err;
        })
}

const AddStatementPhoto = (params) => {

    const statementPhotoFormData = new FormData();
    Object.keys(params).forEach(valKey => {
        statementPhotoFormData.append(valKey, params[valKey])
    })

    return axios.post(endpoints.INVOICING.CASH_MANAGEMENT.CREATE_STATEMENT_PHOTO, statementPhotoFormData)
        .then(res => res.data)
        .catch(err => {
            throw err
        })
}

const EditStatementPhoto = (id: number, params) => {
    const endpoint = generateUrl(endpoints.INVOICING.CASH_MANAGEMENT.EDIT_STATEMENT_PHOTO, { id })

    const statementPhotoFormData = new FormData();
    Object.keys(params).forEach(valKey => {
        statementPhotoFormData.append(valKey, params[valKey])
    })

    return axios.patch(endpoint, statementPhotoFormData)
        .then(res => res.data)
        .catch(err => {
            throw err
        })
}

const DeleteStatementPhoto = (id: number) => {
    const endpoint = generateUrl(endpoints.INVOICING.CASH_MANAGEMENT.DELETE_STATEMENT_PHOTO, { id })

    return axios.delete(endpoint)
        .then(res => res)
        .catch(err => {
            throw err
        })
}

const GetUserIncomeExpenses = (params) => {
    const dateQS = `${params.date ? `date=${params.date}` : ''}`;
    const teamMemberQS = `${params.teamMember ? `&team_member=${params.teamMember}` : ''}`;

    const endpoint = `${endpoints.INVOICING.CASH_MANAGEMENT.INCOME_EXPENSES}?${dateQS}${teamMemberQS}`;

    return axios.get(endpoint)
        .then(res => res.data)
        .catch(err => {
            throw err;
        })
}

export default {
    GetInvoices,
    GetInvoice,
    CreateInvoice,
    EditInvoice,
    EditInvoiceDetails,
    GetEntries,
    GetEntry,
    EditEntry,
    CreateEntry,
    ListEntryPhotos,
    GetEntryPhoto,
    AddEntryPhoto,
    EditEntryPhoto,
    DeleteEntryPhoto,
    PrintTicket,
    ObtainTicket,
    GetStatements,
    GetStatment,
    OpenStatment,
    ApproveStatements,
    DisapproveStatements,
    GetStatementsTotals,
    ExportStatements,
    GetStatementHistory,
    CloseStatement,
    ListStatementPhotos,
    GetStatementPhoto,
    AddStatementPhoto,
    EditStatementPhoto,
    DeleteStatementPhoto,
    GetUserIncomeExpenses,
    ExportInvoices,
    SyncInvoice
}
